




















































































































































































































































































































::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
.actions_wrapper {
  display: flex;
  gap: 10px;
}
.just_for_small {
  display: none;
  @media (min-width: 320px) and (max-width: 991px) {
    display: block;
  }
}
.just_for_large {
  display: block;
  @media (min-width: 320px) and (max-width: 991px) {
    display: none;
  }
}
